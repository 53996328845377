import { APP_ID, APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { take } from 'rxjs';

import { CoreModule } from '@ultra/core';
import { AuthService } from '@ultra/core/auth';
import { ErrorsSentryModule } from '@ultra/core/lib/services/sentry/errors-sentry.module';
import { UserState } from '@ultra/core/lib/stores';
import { WINDOW_STORE_PROVIDER } from '@ultra/core/providers';
import { environment } from '@ultra/environment';
import { ClientVersionGuard, ConfirmationModule, NavbarModule, ToastRootComponent } from '@ultra/shared/modules';
import { UniqFactoriesRecommendationState } from '@ultra/shared/uniq-factory/components';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LibraryGamesState } from './library-games/store/library-games.state';
import { MobileDisclaimerComponent } from './shared/mobile-disclaimer/mobile-disclaimer.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    NgxsModule.forRoot([LibraryGamesState, UserState, UniqFactoriesRecommendationState], {
      compatibility: { strictContentSecurityPolicy: true },
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    CoreModule.withUserStore(),
    ConfirmationModule,
    NavbarModule,
    ErrorsSentryModule,
    ToastRootComponent,
    MobileDisclaimerComponent,
  ],
  providers: [
    WINDOW_STORE_PROVIDER,
    ClientVersionGuard,
    { provide: APP_ID, useValue: 'ultraStore' },
    { provide: 'googleTagManagerId', useValue: environment.gtm },
    // Need to wait until session loading completes to know which version of the store will be shown
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      useFactory: (authService: AuthService) => () => authService.isSessionLoadingDone$.pipe(take(1)),
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
